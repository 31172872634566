.image-container{
    overflow: hidden;  
    position: relative; 
    cursor: all-scroll;
    width: 100%;
    height: 100%;
}


.magnifier-img {
    transition: transform 0.2s ease; 
    width: 100%; 
    height: auto; 
    display: block;
}

.icon-container {
    display: flex;            
    justify-content: center;  
    padding: 0.5rem;
}

