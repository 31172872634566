html, body {
    height: 100%;
    margin: 0;
    overflow: auto; /* Enable scrolling if content exceeds viewport */
  }

  
 
  
  
  .table-wrapper {
    display: block;
    overflow: auto; /* Enable scrolling */
    max-height: 500px; /* Set a maximum height for vertical scrolling */
    position: relative;
  }
  
  table.no-border {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-left:1%;
    margin-top:3%
  }
  
  table.no-border th,
  table.no-border td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    white-space: nowrap;
  }
  
  table.no-border th {
    background-color: #f8f8f8;
    position: sticky;
    top: 0; /* Freeze the first row */
    z-index: 2;
  }
  
  table.no-border td:first-child,
  table.no-border th:first-child {
    position: sticky;
    left: 0; /* Freeze the first column */
    background-color: #f8f8f8; /* Match the header background color */
    z-index: 1; /* Make sure it overlaps other cells */
    width: 310px; /* Increase the width of the first column */
    padding-right: 0px; 
  }
  
  
  table.no-border th:first-child {
    z-index: 3; /* Ensure the top-left cell is above the other frozen cells */
  }
  
  .save-next-button {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
    margin-left:9px;
  }
  
  .save-next-button:hover {
    background-color: darkgreen;
  }

  .save-next-button:disabled {
    background-color: grey; /* Greyed-out color */
    color: lightgrey;       /* Text color when disabled */
    cursor: not-allowed;    /* Not-allowed cursor */
  }
  
  .save-next-button:hover:disabled {
    background-color: grey; /* Keep it grey on hover */
  }


  

  .empty-card {
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: "980px";
    height: "90px";
    top:"520px";
    left:"399px";
    border-radius: 4px;
    margin-top: 3%;
    margin-right: 5%; 
    padding: 10px;
    margin-left:1%

  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: "-3%";
  }
  .card-expanded {
    height: 300px; /* Adjust height when expanded */
  }
  .card-collapsed {
    height: 100px; /* Adjust height when collapsed */
  }
  .toggle-icon {
    cursor: pointer;
    font-size: 100px;
  }
  
  
  .card-content {
    margin-top: 3px;
  }
  
  .cell-content {
    cursor: pointer; /* Change cursor to pointer */
  }
  .line-input {
    border: none;
    border-bottom: 2px solid #FFC72C; /* Bottom border color */
    outline: none; /* Remove outline */
    width: 100%; /* Full width */
    padding: 5px; /* Adjust padding */
    font-size: 14px; /* Font size */
  }
  
  .line-input:focus {
    border-bottom: 2px solid green; /* Change color on focus */
  }
  

  .edit-container {
    position: fixed;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    box-sizing: border-box;
    margin-top:"2%";
  }
  
  .edit-container h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  .edit-container p {
    font-size: 16px;
    color: #666;
  }
  
  .edit-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .edit-container button:hover {
    background-color: #c82333;
  }

  .dropdown-container {
    margin: 10px 0;
  }
  
  
  .button-content {
    display: flex;
    align-items: flex-start; /* Aligns content to the start */
    flex-shrink: 1; 
    overflow: hidden; 
  }
  
  .button-text {
    color:black;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px; /* Adjust to control how much space the text takes up */
    font-size: 14px;
  }

  .button-content:hover .button-text {
    white-space: normal; /* Allows the text to wrap */
  }

  .no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust height as needed */
  }
  
  .no-data-container span {
    font-size: 14px; /* Adjust font size as needed */
    color: #333;
    margin-right:10%
  }
  .line-input {
    border: none;
    outline: none; /* Remove the default outline */
    padding: 5px; /* Spacing */
    width: 100%; /* Full width */
    font-size: 16px; /* Font size */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.line-input:focus {
    border-bottom: 1px solid black; /* Change color on focus */
}

.add-empty-row-button {
  display: flex;              /* Align children in a row */
  align-items: center;         /* Vertically align icon and text */
  justify-content: center;     /* Center align both horizontally */
  transform: translateY(19px);
  margin-left: 1%;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  background-color: #00ACEA;
  border-color: #00ACEA;
  color: #ffffff;
  cursor: pointer;
}

.total-image {
  display: flex;              /* Align children in a row */
  align-items: center;         /* Vertically align icon and text */
  justify-content: center;     /* Center align both horizontally */
  transform: translateY(19px);
  margin-left: 1%;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  background-color:#4770c1;
  border-color: #4770c1;
  color: #ffffff;
  cursor: pointer;
  margin-right:57px;
}
.left-aligned-slider {
  justify-content: flex-start;
  display: flex !important; /* Ensure the slider flexes */
}


/* Custom CSS for the table */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
}

.custom-table thead {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

.custom-table th, .custom-table td {
  padding: 12px 15px;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}

.custom-table th {
  border-bottom: 2px solid #ddd;
}

.custom-table td {
  border-bottom: 1px solid #ddd;
}

/* Optional: Style for table text */
.custom-table td {
  color: #333;
}

.custom-table td, .custom-table th {
  text-align: left;
}



  
  
  
  



  